import { req } from 'vuelidate/lib/validators/common';

export default value => {
  // Check if a value has been passed in
  if (!req(value)) {
    return true;
  }

  // account number must start with 7 and be 8 digits
  const accountNumberRegex = /7[0-9]{7}/;
  return accountNumberRegex.test(value);
};
