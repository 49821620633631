import { req } from 'vuelidate/lib/validators/common';
import { email } from 'vuelidate/lib/validators';

export default value => {
  // Check if a value has been passed in
  if (!req(value)) {
    return true;
  }

  // Check the email passes the base Vuelidate checks
  if (!email(value)) {
    return false;
  }

  let emailParts = value.split('@');
  let localPart = emailParts[0];
  if (localPart.length > 64) {
    return false;
  }

  let domainPart = emailParts[1];
  if (domainPart.length > 255) {
    return false;
  }

  // Taken from regex at bottom of this section https://html.spec.whatwg.org/#e-mail-state-(type=email)
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const isValid = emailRegex.test(value);

  return isValid;
};
