<template>
  <div
    class="mobile-full-page-wrapper"
  >
    <div class="inner">
      <slot />
      <icon-button
        v-if="showArrow && $vuetify.breakpoint.xsOnly"
        :class="['scroll-arrow', `scroll-arrow--${arrowColor}`]"
        @click="onScroll"
      >
        <arrow-icon />
      </icon-button>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/atoms/IconButton.vue';
import ArrowIcon from '@/assets/icons/scroll-arrow.svg';

export default {
  components: {
    IconButton,
    ArrowIcon
  },
  props: {
    goToId: {
      type: String,
      default: ''
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    arrowColor: {
      type: String,
      default: 'orange',
      validator: value => {
        return ['orange', 'white'].includes(value);
      }
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onScroll() {
      if (this.goToId) {
        this.$vuetify.goTo(`#${this.goToId}`, {
          offset: this.offset
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.mobile-full-page-wrapper {
  width: 100%;

  // IE11 fix
  @include xs-only {
    display: flex;
  }
}

.inner {
  width: 100%;

  @include xs-only {
    position: relative;
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(75px 0);
  }
}

.scroll-arrow {
  position: absolute;
  bottom: rem(50px);
  left: 50%;
  transform: translate(-50%, 0);

  &--orange {
  }

  &--white {
    /deep/ svg {
      g {
        stroke: $white;
      }
    }
  }
}
</style>
